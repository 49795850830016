export const cities = {
    "Akurana#10": {
        cityId: 10,
        name: "Akurana",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Aladeniya#13": {
        cityId: 13,
        name: "Aladeniya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Alawatugoda#15": {
        cityId: 15,
        name: "Alawatugoda",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Ankubura#33": {
        cityId: 33,
        name: "Ankubura",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Bokkawala#92": {
        cityId: 92,
        name: "Bokkawala",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Delpitiya#153": {
        cityId: 153,
        name: "Delpitiya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Deltota#154": {
        cityId: 154,
        name: "Deltota",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Galagedara#192": {
        cityId: 192,
        name: "Galagedara",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Galaha#194": {
        cityId: 194,
        name: "Galaha",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Gampola#209": {
        cityId: 209,
        name: "Gampola",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Gurudeniya - Gelioya#229": {
        cityId: 229,
        name: "Gurudeniya - Gelioya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Hasalaka#239": {
        cityId: 239,
        name: "Hasalaka",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Hataraliyadda#240": {
        cityId: 240,
        name: "Hataraliyadda",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Hunnasgiriya#265": {
        cityId: 265,
        name: "Hunnasgiriya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Kadugannawa#286": {
        cityId: 286,
        name: "Kadugannawa",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Kandy#327": {
        cityId: 327,
        name: "Kandy",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Karaliyedde#333": {
        cityId: 333,
        name: "Karaliyedde",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Katugasthota#342": {
        cityId: 342,
        name: "Katugasthota",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Kengalla#360": {
        cityId: 360,
        name: "Kengalla",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Kundasale#409": {
        cityId: 409,
        name: "Kundasale",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Madawala#423": {
        cityId: 423,
        name: "Madawala",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Mailapitiya#441": {
        cityId: 441,
        name: "Mailapitiya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Menikhinna#493": {
        cityId: 493,
        name: "Menikhinna",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Nawalapitiya#552": {
        cityId: 552,
        name: "Nawalapitiya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Nugawela#587": {
        cityId: 587,
        name: "Nugawela",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Panwila#622": {
        cityId: 622,
        name: "Panwila",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Peradeniya#634": {
        cityId: 634,
        name: "Peradeniya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Pilimathalawa#641": {
        cityId: 641,
        name: "Pilimathalawa",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Pujapitiya#668": {
        cityId: 668,
        name: "Pujapitiya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Pussellawa#678": {
        cityId: 678,
        name: "Pussellawa",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Rajawella#688": {
        cityId: 688,
        name: "Rajawella",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Rikillagaskada#703": {
        cityId: 703,
        name: "Rikillagaskada",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Talatuoya#735": {
        cityId: 735,
        name: "Talatuoya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Teldeniya#744": {
        cityId: 744,
        name: "Teldeniya",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Tennekumbura#746": {
        cityId: 746,
        name: "Tennekumbura",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Thalakiriyagama#750": {
        cityId: 750,
        name: "Thalakiriyagama",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Udatenna - Medamaha Nuwara#784": {
        cityId: 784,
        name: "Udatenna - Medamaha Nuwara",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Ududumbara#786": {
        cityId: 786,
        name: "Ududumbara",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Wattegama#837": {
        cityId: 837,
        name: "Wattegama",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Weligalla#846": {
        cityId: 846,
        name: "Weligalla",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Werellagama#868": {
        cityId: 868,
        name: "Werellagama",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Ambathanna#881": {
        cityId: 881,
        name: "Ambathanna",
        districtId: "7",
        districtName: "Kandy",
        provinceName: "Central",
        provinceId: "4",
    },
    "Dambulla#143": {
        cityId: 143,
        name: "Dambulla",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Devahuwa#159": {
        cityId: 159,
        name: "Devahuwa",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Galewela#197": {
        cityId: 197,
        name: "Galewela",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Inamaluwa#273": {
        cityId: 273,
        name: "Inamaluwa",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Matale#467": {
        cityId: 467,
        name: "Matale",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Naula#548": {
        cityId: 548,
        name: "Naula",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Neelagama#558": {
        cityId: 558,
        name: "Neelagama",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Palapathwala#603": {
        cityId: 603,
        name: "Palapathwala",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Pallepola#606": {
        cityId: 606,
        name: "Pallepola",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Rattota#700": {
        cityId: 700,
        name: "Rattota",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Ukuwela#793": {
        cityId: 793,
        name: "Ukuwela",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Wilgamuwa#870": {
        cityId: 870,
        name: "Wilgamuwa",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Yatawatta#878": {
        cityId: 878,
        name: "Yatawatta",
        districtId: "10",
        districtName: "Matale",
        provinceName: "Central",
        provinceId: "4",
    },
    "Ginigathena#215": {
        cityId: 215,
        name: "Ginigathena",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Hanguranketa#236": {
        cityId: 236,
        name: "Hanguranketa",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Hatton#241": {
        cityId: 241,
        name: "Hatton",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Kandapola#325": {
        cityId: 325,
        name: "Kandapola",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Keertibandarapura#353": {
        cityId: 353,
        name: "Keertibandarapura",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Kotagala#394": {
        cityId: 394,
        name: "Kotagala",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Maskeliya#465": {
        cityId: 465,
        name: "Maskeliya",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Nanu Oya#541": {
        cityId: 541,
        name: "Nanu Oya",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Nortonbridge#584": {
        cityId: 584,
        name: "Nortonbridge",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Norwood#585": {
        cityId: 585,
        name: "Norwood",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Nuwara Eliya#590": {
        cityId: 590,
        name: "Nuwara Eliya",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Padiyapelella#598": {
        cityId: 598,
        name: "Padiyapelella",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Pundaluoya#674": {
        cityId: 674,
        name: "Pundaluoya",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Ragala#684": {
        cityId: 684,
        name: "Ragala",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Talawakele#737": {
        cityId: 737,
        name: "Talawakele",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Tawalantenna#743": {
        cityId: 743,
        name: "Tawalantenna",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Udupussellawa#789": {
        cityId: 789,
        name: "Udupussellawa",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Wattawala#836": {
        cityId: 836,
        name: "Wattawala",
        districtId: "11",
        districtName: "Nuwara Eliya",
        provinceName: "Central",
        provinceId: "4",
    },
    "Arasaditivu - Kokkaddicholai#40": {
        cityId: 40,
        name: "Arasaditivu - Kokkaddicholai",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kokkaddichcholai#381": {
        cityId: 381,
        name: "Kokkaddichcholai",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Arayampathy#41": {
        cityId: 41,
        name: "Arayampathy",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Batticaloa#74": {
        cityId: 74,
        name: "Batticaloa",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Chenkaladi#110": {
        cityId: 110,
        name: "Chenkaladi",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Eravur#189": {
        cityId: 189,
        name: "Eravur",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kalawanchikudy#302": {
        cityId: 302,
        name: "Kalawanchikudy",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kaluwankarni#313": {
        cityId: 313,
        name: "Kaluwankarni",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kattankudy#340": {
        cityId: 340,
        name: "Kattankudy",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kirankulam#370": {
        cityId: 370,
        name: "Kirankulam",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kurukkalmadam#411": {
        cityId: 411,
        name: "Kurukkalmadam",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Main Street - Valachchenai#442": {
        cityId: 442,
        name: "Main Street - Valachchenai",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Valachchenai - Oddamawadi#804": {
        cityId: 804,
        name: "Valachchenai - Oddamawadi",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Valachchenai#803": {
        cityId: 803,
        name: "Valachchenai",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Mandoor - Palamunai#453": {
        cityId: 453,
        name: "Mandoor - Palamunai",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Periyakallar#636": {
        cityId: 636,
        name: "Periyakallar",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Periyaporathvu#637": {
        cityId: 637,
        name: "Periyaporathvu",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Rideetenna#701": {
        cityId: 701,
        name: "Rideetenna",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Thalankuda#752": {
        cityId: 752,
        name: "Thalankuda",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Thiavaddavan - Oddamavadi#759": {
        cityId: 759,
        name: "Thiavaddavan - Oddamavadi",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Vakarai#802": {
        cityId: 802,
        name: "Vakarai",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Vellavely#812": {
        cityId: 812,
        name: "Vellavely",
        districtId: "12",
        districtName: "Batticaloa",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Addalachchenai#1": {
        cityId: 1,
        name: "Addalachchenai",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Akkaraipattu#6": {
        cityId: 6,
        name: "Akkaraipattu",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Alayadivembu#17": {
        cityId: 17,
        name: "Alayadivembu",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Ampara#28": {
        cityId: 28,
        name: "Ampara",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Chawalakadai#108": {
        cityId: 108,
        name: "Chawalakadai",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Dehiatthakandiya#147": {
        cityId: 147,
        name: "Dehiatthakandiya",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Deiattakandiya#150": {
        cityId: 150,
        name: "Deiattakandiya",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Hingurana#252": {
        cityId: 252,
        name: "Hingurana",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Irakkamam#276": {
        cityId: 276,
        name: "Irakkamam",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kalmunai#303": {
        cityId: 303,
        name: "Kalmunai",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kalmunaikudy - 14#305": {
        cityId: 305,
        name: "Kalmunaikudy - 14",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Karaitivu - Ep#332": {
        cityId: 332,
        name: "Karaitivu - Ep",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Maha Oya#429": {
        cityId: 429,
        name: "Maha Oya",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Nintavur#578": {
        cityId: 578,
        name: "Nintavur",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Oluvil#592": {
        cityId: 592,
        name: "Oluvil",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Padiyatalawa#599": {
        cityId: 599,
        name: "Padiyatalawa",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Pottuvil#662": {
        cityId: 662,
        name: "Pottuvil",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Sainthamaruthu#705": {
        cityId: 705,
        name: "Sainthamaruthu",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Samanthurai#708": {
        cityId: 708,
        name: "Samanthurai",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Sandunpura#711": {
        cityId: 711,
        name: "Sandunpura",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Tirukkovil#774": {
        cityId: 774,
        name: "Tirukkovil",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Uhana#792": {
        cityId: 792,
        name: "Uhana",
        districtId: "13",
        districtName: "Ampara",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Gantalawa#214": {
        cityId: 214,
        name: "Gantalawa",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Gomarankadawela#225": {
        cityId: 225,
        name: "Gomarankadawela",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kantale#330": {
        cityId: 330,
        name: "Kantale",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kinniya#367": {
        cityId: 367,
        name: "Kinniya",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Kuchchiveli#402": {
        cityId: 402,
        name: "Kuchchiveli",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Morawewa#512": {
        cityId: 512,
        name: "Morawewa",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Mutur#527": {
        cityId: 527,
        name: "Mutur",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Nilaveli#573": {
        cityId: 573,
        name: "Nilaveli",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Nilaveli - Irrakkandy#574": {
        cityId: 574,
        name: "Nilaveli - Irrakkandy",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Pulmoddai#671": {
        cityId: 671,
        name: "Pulmoddai",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Serunuwara#715": {
        cityId: 715,
        name: "Serunuwara",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Sinna Kinniya#719": {
        cityId: 719,
        name: "Sinna Kinniya",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Siripura#722": {
        cityId: 722,
        name: "Siripura",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Trincomalee#780": {
        cityId: 780,
        name: "Trincomalee",
        districtId: "14",
        districtName: "Trincomalee",
        provinceName: "Eastern",
        provinceId: "8",
    },
    "Alaveddy#14": {
        cityId: 14,
        name: "Alaveddy",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Atchuvely#45": {
        cityId: 45,
        name: "Atchuvely",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Chankanai#106": {
        cityId: 106,
        name: "Chankanai",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Chavakachcheri#107": {
        cityId: 107,
        name: "Chavakachcheri",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Chunnakam#114": {
        cityId: 114,
        name: "Chunnakam",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Jaffna#281": {
        cityId: 281,
        name: "Jaffna",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Kaithady#293": {
        cityId: 293,
        name: "Kaithady",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Karainagar#331": {
        cityId: 331,
        name: "Karainagar",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Kayts#351": {
        cityId: 351,
        name: "Kayts",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Kodikamam#379": {
        cityId: 379,
        name: "Kodikamam",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Kokuvil#383": {
        cityId: 383,
        name: "Kokuvil",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Kopay#385": {
        cityId: 385,
        name: "Kopay",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Kuncharkadai#408": {
        cityId: 408,
        name: "Kuncharkadai",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Mallakam#447": {
        cityId: 447,
        name: "Mallakam",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Manipay#454": {
        cityId: 454,
        name: "Manipay",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Manthikai#457": {
        cityId: 457,
        name: "Manthikai",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Maruthanarmadam#464": {
        cityId: 464,
        name: "Maruthanarmadam",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Mathagal#471": {
        cityId: 471,
        name: "Mathagal",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Myliddy#528": {
        cityId: 528,
        name: "Myliddy",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Nallur#536": {
        cityId: 536,
        name: "Nallur",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Nelliady#566": {
        cityId: 566,
        name: "Nelliady",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Pandatharippu#614": {
        cityId: 614,
        name: "Pandatharippu",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Puloly#672": {
        cityId: 672,
        name: "Puloly",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Punkudutivu#675": {
        cityId: 675,
        name: "Punkudutivu",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Punnalaikaduvan#676": {
        cityId: 676,
        name: "Punnalaikaduvan",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Puttur#681": {
        cityId: 681,
        name: "Puttur",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Sandilipay#710": {
        cityId: 710,
        name: "Sandilipay",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Siruppiddy#725": {
        cityId: 725,
        name: "Siruppiddy",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Talayadi#738": {
        cityId: 738,
        name: "Talayadi",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Tellippalai#745": {
        cityId: 745,
        name: "Tellippalai",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Thirunelveli#765": {
        cityId: 765,
        name: "Thirunelveli",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Uduvil#790": {
        cityId: 790,
        name: "Uduvil",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Urumpirai#800": {
        cityId: 800,
        name: "Urumpirai",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Valvettithurai#805": {
        cityId: 805,
        name: "Valvettithurai",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Velanai#811": {
        cityId: 811,
        name: "Velanai",
        districtId: "17",
        districtName: "Jaffna",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Mannar#456": {
        cityId: 456,
        name: "Mannar",
        districtId: "18",
        districtName: "Mannar",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Murungan#524": {
        cityId: 524,
        name: "Murungan",
        districtId: "18",
        districtName: "Mannar",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Nanattan#539": {
        cityId: 539,
        name: "Nanattan",
        districtId: "18",
        districtName: "Mannar",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Silawattura#718": {
        cityId: 718,
        name: "Silawattura",
        districtId: "18",
        districtName: "Mannar",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Siritoppu#724": {
        cityId: 724,
        name: "Siritoppu",
        districtId: "18",
        districtName: "Mannar",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Thalaimannar Pier#734": {
        cityId: 734,
        name: "Thalaimannar Pier",
        districtId: "18",
        districtName: "Mannar",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Thalaimannar#749": {
        cityId: 749,
        name: "Thalaimannar",
        districtId: "18",
        districtName: "Mannar",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Tharapuram#757": {
        cityId: 757,
        name: "Tharapuram",
        districtId: "18",
        districtName: "Mannar",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Mallavi#448": {
        cityId: 448,
        name: "Mallavi",
        districtId: "19",
        districtName: "Mulalativu",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Thunnukai - Mallavi#772": {
        cityId: 772,
        name: "Thunnukai - Mallavi",
        districtId: "19",
        districtName: "Mulalativu",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Mankulam#455": {
        cityId: 455,
        name: "Mankulam",
        districtId: "19",
        districtName: "Mulalativu",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Mulattivu#518": {
        cityId: 518,
        name: "Mulattivu",
        districtId: "19",
        districtName: "Mulalativu",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Mulliyawalai#521": {
        cityId: 521,
        name: "Mulliyawalai",
        districtId: "19",
        districtName: "Mulalativu",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Nayaru#554": {
        cityId: 554,
        name: "Nayaru",
        districtId: "19",
        districtName: "Mulalativu",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Oddusuddan#591": {
        cityId: 591,
        name: "Oddusuddan",
        districtId: "19",
        districtName: "Mulalativu",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Puthukudiyiruppu#679": {
        cityId: 679,
        name: "Puthukudiyiruppu",
        districtId: "19",
        districtName: "Mulalativu",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Visvamadhukulam#818": {
        cityId: 818,
        name: "Visvamadhukulam",
        districtId: "19",
        districtName: "Mulalativu",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Cheddikulam#109": {
        cityId: 109,
        name: "Cheddikulam",
        districtId: "20",
        districtName: "Vavuniya",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Kanagarayamkulam#318": {
        cityId: 318,
        name: "Kanagarayamkulam",
        districtId: "20",
        districtName: "Vavuniya",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Nedunkarny#557": {
        cityId: 557,
        name: "Nedunkarny",
        districtId: "20",
        districtName: "Vavuniya",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Omanthai#593": {
        cityId: 593,
        name: "Omanthai",
        districtId: "20",
        districtName: "Vavuniya",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Vavuniya#809": {
        cityId: 809,
        name: "Vavuniya",
        districtId: "20",
        districtName: "Vavuniya",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Akkarayankulam#9": {
        cityId: 9,
        name: "Akkarayankulam",
        districtId: "21",
        districtName: "Killinochchi",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Iyakachchi#278": {
        cityId: 278,
        name: "Iyakachchi",
        districtId: "21",
        districtName: "Killinochchi",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Kilinochchi#365": {
        cityId: 365,
        name: "Kilinochchi",
        districtId: "21",
        districtName: "Killinochchi",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Mulankavil#517": {
        cityId: 517,
        name: "Mulankavil",
        districtId: "21",
        districtName: "Killinochchi",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Pallai#604": {
        cityId: 604,
        name: "Pallai",
        districtId: "21",
        districtName: "Killinochchi",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Paranthan#625": {
        cityId: 625,
        name: "Paranthan",
        districtId: "21",
        districtName: "Killinochchi",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Vadiyadi#801": {
        cityId: 801,
        name: "Vadiyadi",
        districtId: "21",
        districtName: "Killinochchi",
        provinceName: "Northern",
        provinceId: "6",
    },
    "Anuradhapura#34": {
        cityId: 34,
        name: "Anuradhapura",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Bogaswewa - Ikirigollewa#91": {
        cityId: 91,
        name: "Bogaswewa - Ikirigollewa",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Bulnewa#103": {
        cityId: 103,
        name: "Bulnewa",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Eppawela#188": {
        cityId: 188,
        name: "Eppawela",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Galenbindunuwewa#196": {
        cityId: 196,
        name: "Galenbindunuwewa",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Galkulama#200": {
        cityId: 200,
        name: "Galkulama",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Galnewa#206": {
        cityId: 206,
        name: "Galnewa",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Habarana#231": {
        cityId: 231,
        name: "Habarana",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Horowupotana#263": {
        cityId: 263,
        name: "Horowupotana",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Ihalawewa - Kiralogama#269": {
        cityId: 269,
        name: "Ihalawewa - Kiralogama",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Kahatagasdigiliya#291": {
        cityId: 291,
        name: "Kahatagasdigiliya",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Kebitigollawa#352": {
        cityId: 352,
        name: "Kebitigollawa",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Kekirawa#356": {
        cityId: 356,
        name: "Kekirawa",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Korasagalla#387": {
        cityId: 387,
        name: "Korasagalla",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Maha Vilachchiya#430": {
        cityId: 430,
        name: "Maha Vilachchiya",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Maradankadawala#460": {
        cityId: 460,
        name: "Maradankadawala",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Medawachchiya#483": {
        cityId: 483,
        name: "Medawachchiya",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Mihintale#495": {
        cityId: 495,
        name: "Mihintale",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Nochchiyagama#582": {
        cityId: 582,
        name: "Nochchiyagama",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Oyamaduwa#596": {
        cityId: 596,
        name: "Oyamaduwa",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Padaviya#597": {
        cityId: 597,
        name: "Padaviya",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Pothanegama#661": {
        cityId: 661,
        name: "Pothanegama",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Pubbogama#666": {
        cityId: 666,
        name: "Pubbogama",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Rajanganaya#687": {
        cityId: 687,
        name: "Rajanganaya",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Rambewa#690": {
        cityId: 690,
        name: "Rambewa",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Sippukulama#721": {
        cityId: 721,
        name: "Sippukulama",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Talawa#736": {
        cityId: 736,
        name: "Talawa",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Thambuthegama#756": {
        cityId: 756,
        name: "Thambuthegama",
        districtId: "15",
        districtName: "Anuradhapura",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Aralaganwila#37": {
        cityId: 37,
        name: "Aralaganwila",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Bakamuna#58": {
        cityId: 58,
        name: "Bakamuna",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Damanayaya#140": {
        cityId: 140,
        name: "Damanayaya",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Diyasenapura#169": {
        cityId: 169,
        name: "Diyasenapura",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Elahera#184": {
        cityId: 184,
        name: "Elahera",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Giritale#218": {
        cityId: 218,
        name: "Giritale",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Hingurakdamana#250": {
        cityId: 250,
        name: "Hingurakdamana",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Hingurakgoda#251": {
        cityId: 251,
        name: "Hingurakgoda",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Kaduruwela#287": {
        cityId: 287,
        name: "Kaduruwela",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Kaudulla#350": {
        cityId: 350,
        name: "Kaudulla",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Manampitiya#452": {
        cityId: 452,
        name: "Manampitiya",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Medirigiriya#486": {
        cityId: 486,
        name: "Medirigiriya",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Minneriya#497": {
        cityId: 497,
        name: "Minneriya",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Palugasdamana#609": {
        cityId: 609,
        name: "Palugasdamana",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Pansal Godella#621": {
        cityId: 621,
        name: "Pansal Godella",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Polonnaruwa#655": {
        cityId: 655,
        name: "Polonnaruwa",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Pulasthigama#669": {
        cityId: 669,
        name: "Pulasthigama",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Sewanapitiya#716": {
        cityId: 716,
        name: "Sewanapitiya",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Siripura - Dehiattakandiya#723": {
        cityId: 723,
        name: "Siripura - Dehiattakandiya",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Welikanda#850": {
        cityId: 850,
        name: "Welikanda",
        districtId: "16",
        districtName: "Polonnaruwa",
        provinceName: "North Central",
        provinceId: "7",
    },
    "Alawwa#16": {
        cityId: 16,
        name: "Alawwa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Ambanpola#25": {
        cityId: 25,
        name: "Ambanpola",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Bamunawala#62": {
        cityId: 62,
        name: "Bamunawala",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Bandarakoswatte#64": {
        cityId: 64,
        name: "Bandarakoswatte",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Barampola Junction#67": {
        cityId: 67,
        name: "Barampola Junction",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Battulu Oya#77": {
        cityId: 77,
        name: "Battulu Oya",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Bingiriya#89": {
        cityId: 89,
        name: "Bingiriya",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Dambadeniya#141": {
        cityId: 141,
        name: "Dambadeniya",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Dodangaslanda#174": {
        cityId: 174,
        name: "Dodangaslanda",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Dummalasuriya#178": {
        cityId: 178,
        name: "Dummalasuriya",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Galapitamulla#195": {
        cityId: 195,
        name: "Galapitamulla",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Galgamuwa#198": {
        cityId: 198,
        name: "Galgamuwa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Giriulla#219": {
        cityId: 219,
        name: "Giriulla",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Gokarella#224": {
        cityId: 224,
        name: "Gokarella",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Gonagama#226": {
        cityId: 226,
        name: "Gonagama",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Hettipola#245": {
        cityId: 245,
        name: "Hettipola",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Hiripitiya#255": {
        cityId: 255,
        name: "Hiripitiya",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Horambawa#260": {
        cityId: 260,
        name: "Horambawa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Ibbagamuwa#266": {
        cityId: 266,
        name: "Ibbagamuwa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kadahapola#284": {
        cityId: 284,
        name: "Kadahapola",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kalugamuwa Junction - Kalugamuwa#310": {
        cityId: 310,
        name: "Kalugamuwa Junction - Kalugamuwa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kamburupola#317": {
        cityId: 317,
        name: "Kamburupola",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kandanagedera#324": {
        cityId: 324,
        name: "Kandanagedera",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Katumuluwa#344": {
        cityId: 344,
        name: "Katumuluwa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Katupotha#347": {
        cityId: 347,
        name: "Katupotha",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Keppetiwalana#362": {
        cityId: 362,
        name: "Keppetiwalana",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Ketawalagedara - Wewagama#364": {
        cityId: 364,
        name: "Ketawalagedara - Wewagama",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kobeigane#377": {
        cityId: 377,
        name: "Kobeigane",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kuliyapitiya#404": {
        cityId: 404,
        name: "Kuliyapitiya",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kumbukgate#406": {
        cityId: 406,
        name: "Kumbukgate",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kurunegala#412": {
        cityId: 412,
        name: "Kurunegala",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Maho#440": {
        cityId: 440,
        name: "Maho",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Makadura#443": {
        cityId: 443,
        name: "Makadura",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Maspotha#466": {
        cityId: 466,
        name: "Maspotha",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Mavee Ela Junction#476": {
        cityId: 476,
        name: "Mavee Ela Junction",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Mawathagama#480": {
        cityId: 480,
        name: "Mawathagama",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Meegalawa#488": {
        cityId: 488,
        name: "Meegalawa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Melsiripura#492": {
        cityId: 492,
        name: "Melsiripura",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Minuwangate#498": {
        cityId: 498,
        name: "Minuwangate",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Mudukatuwa#515": {
        cityId: 515,
        name: "Mudukatuwa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Nagollagama#530": {
        cityId: 530,
        name: "Nagollagama",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Nagollagoda#531": {
        cityId: 531,
        name: "Nagollagoda",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Nankawaththa#540": {
        cityId: 540,
        name: "Nankawaththa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Narammala#543": {
        cityId: 543,
        name: "Narammala",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Narangoda#544": {
        cityId: 544,
        name: "Narangoda",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Nikaweratiya#572": {
        cityId: 572,
        name: "Nikaweratiya",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Pannala#617": {
        cityId: 617,
        name: "Pannala",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Pilessa#639": {
        cityId: 639,
        name: "Pilessa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Polgahawela#653": {
        cityId: 653,
        name: "Polgahawela",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Polpithigama#657": {
        cityId: 657,
        name: "Polpithigama",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Potuhera#665": {
        cityId: 665,
        name: "Potuhera",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Ridigama#702": {
        cityId: 702,
        name: "Ridigama",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Saliya Ashokapura#706": {
        cityId: 706,
        name: "Saliya Ashokapura",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Sandalankawa#709": {
        cityId: 709,
        name: "Sandalankawa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Thuththiripitigama - Kadawalagedara#773": {
        cityId: 773,
        name: "Thuththiripitigama - Kadawalagedara",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Udubaddawa#785": {
        cityId: 785,
        name: "Udubaddawa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Vilaththawa - Puliyamkadawala#817": {
        cityId: 817,
        name: "Vilaththawa - Puliyamkadawala",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Wariyapola#833": {
        cityId: 833,
        name: "Wariyapola",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "We - Uda#839": {
        cityId: 839,
        name: "We - Uda",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Weerapokuna#843": {
        cityId: 843,
        name: "Weerapokuna",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Wellawa#863": {
        cityId: 863,
        name: "Wellawa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Welpothuwewa - Boruluwewa#865": {
        cityId: 865,
        name: "Welpothuwewa - Boruluwewa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Wennoruwa#867": {
        cityId: 867,
        name: "Wennoruwa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Withikuliya Junction#872": {
        cityId: 872,
        name: "Withikuliya Junction",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Yakwila - Paragammana#876": {
        cityId: 876,
        name: "Yakwila - Paragammana",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Ilippugamuwa#883": {
        cityId: 883,
        name: "Ilippugamuwa",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kurunegala Rd - Bopitiya#886": {
        cityId: 886,
        name: "Kurunegala Rd - Bopitiya",
        districtId: "6",
        districtName: "Kurunegala",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Anamaduwa#29": {
        cityId: 29,
        name: "Anamaduwa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Arachchikattuwa#35": {
        cityId: 35,
        name: "Arachchikattuwa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Arndigama#42": {
        cityId: 42,
        name: "Arndigama",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Bangadeniya#66": {
        cityId: 66,
        name: "Bangadeniya",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Botalegama#99": {
        cityId: 99,
        name: "Botalegama",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Chilaw#112": {
        cityId: 112,
        name: "Chilaw",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Dankotuwa#145": {
        cityId: 145,
        name: "Dankotuwa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Etalai#190": {
        cityId: 190,
        name: "Etalai",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Inigodawela#275": {
        cityId: 275,
        name: "Inigodawela",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kakkapalliya#294": {
        cityId: 294,
        name: "Kakkapalliya",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kalpitiya#306": {
        cityId: 306,
        name: "Kalpitiya",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kalpitiya Road - Mampuriya#307": {
        cityId: 307,
        name: "Kalpitiya Road - Mampuriya",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Mampuriya#451": {
        cityId: 451,
        name: "Mampuriya",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kandakudah#320": {
        cityId: 320,
        name: "Kandakudah",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kandathoduwawa#326": {
        cityId: 326,
        name: "Kandathoduwawa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Katuneriya#346": {
        cityId: 346,
        name: "Katuneriya",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Kokkawila#382": {
        cityId: 382,
        name: "Kokkawila",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Lunuwila#418": {
        cityId: 418,
        name: "Lunuwila",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Madampe#420": {
        cityId: 420,
        name: "Madampe",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Madurankuliya#426": {
        cityId: 426,
        name: "Madurankuliya",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Mahawewa#435": {
        cityId: 435,
        name: "Mahawewa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Mahawewa - Thoduwawa#436": {
        cityId: 436,
        name: "Mahawewa - Thoduwawa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Marawila#462": {
        cityId: 462,
        name: "Marawila",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Medawakkulama - Andigama#484": {
        cityId: 484,
        name: "Medawakkulama - Andigama",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Mundelama#523": {
        cityId: 523,
        name: "Mundelama",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Nalladarankattuwa#535": {
        cityId: 535,
        name: "Nalladarankattuwa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Nattandiya#546": {
        cityId: 546,
        name: "Nattandiya",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Nattandiya - Koswatta#547": {
        cityId: 547,
        name: "Nattandiya - Koswatta",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Nawagaththegama#550": {
        cityId: 550,
        name: "Nawagaththegama",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Noraicholai#583": {
        cityId: 583,
        name: "Noraicholai",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Panirendawa#616": {
        cityId: 616,
        name: "Panirendawa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Puliyankulama Junction#670": {
        cityId: 670,
        name: "Puliyankulama Junction",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Puttalam#680": {
        cityId: 680,
        name: "Puttalam",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Saliyawewa#707": {
        cityId: 707,
        name: "Saliyawewa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Serakuliya#714": {
        cityId: 714,
        name: "Serakuliya",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Tabbowa#733": {
        cityId: 733,
        name: "Tabbowa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Thambarawila#755": {
        cityId: 755,
        name: "Thambarawila",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Toppuwa Junction#778": {
        cityId: 778,
        name: "Toppuwa Junction",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Vanatavilluwa#807": {
        cityId: 807,
        name: "Vanatavilluwa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Waikkal#822": {
        cityId: 822,
        name: "Waikkal",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Welipennagahamulla#854": {
        cityId: 854,
        name: "Welipennagahamulla",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Wellamankara#861": {
        cityId: 861,
        name: "Wellamankara",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Wennappuwa#866": {
        cityId: 866,
        name: "Wennappuwa",
        districtId: "8",
        districtName: "Puttalam",
        provinceName: "North Western",
        provinceId: "3",
    },
    "Amithirigala#27": {
        cityId: 27,
        name: "Amithirigala",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Aranayake#39": {
        cityId: 39,
        name: "Aranayake",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Bulathkohupitiya#101": {
        cityId: 101,
        name: "Bulathkohupitiya",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Dehiowita#148": {
        cityId: 148,
        name: "Dehiowita",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Deraniyagala#158": {
        cityId: 158,
        name: "Deraniyagala",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Galigamuwa#199": {
        cityId: 199,
        name: "Galigamuwa",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Hemmathagama#244": {
        cityId: 244,
        name: "Hemmathagama",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Imbulgasdeniya#272": {
        cityId: 272,
        name: "Imbulgasdeniya",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Kegalle#354": {
        cityId: 354,
        name: "Kegalle",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Kithulgala#376": {
        cityId: 376,
        name: "Kithulgala",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Mattamagoda - Kotiyakumbura#473": {
        cityId: 473,
        name: "Mattamagoda - Kotiyakumbura",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Mawanella#477": {
        cityId: 477,
        name: "Mawanella",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Nelundeniya#569": {
        cityId: 569,
        name: "Nelundeniya",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Rambukkana#691": {
        cityId: 691,
        name: "Rambukkana",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Ruwanwella#704": {
        cityId: 704,
        name: "Ruwanwella",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Thulhiriya#769": {
        cityId: 769,
        name: "Thulhiriya",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Waharaka#821": {
        cityId: 821,
        name: "Waharaka",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Warakapola#830": {
        cityId: 830,
        name: "Warakapola",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Weligamuwa - Rambukkana#849": {
        cityId: 849,
        name: "Weligamuwa - Rambukkana",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Yatiyantota#880": {
        cityId: 880,
        name: "Yatiyantota",
        districtId: "22",
        districtName: "Kegalle",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Atakalampanna#43": {
        cityId: 43,
        name: "Atakalampanna",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Balangoda#59": {
        cityId: 59,
        name: "Balangoda",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Divurmpitiya - Gatahatta#168": {
        cityId: 168,
        name: "Divurmpitiya - Gatahatta",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Eheliyagoda#182": {
        cityId: 182,
        name: "Eheliyagoda",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Embilipitiya#186": {
        cityId: 186,
        name: "Embilipitiya",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Ganegama - Pelmadulla#211": {
        cityId: 211,
        name: "Ganegama - Pelmadulla",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Godakawela#223": {
        cityId: 223,
        name: "Godakawela",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Hidellana - Rathnapura#247": {
        cityId: 247,
        name: "Hidellana - Rathnapura",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Kahawatta#292": {
        cityId: 292,
        name: "Kahawatta",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Kalawana#300": {
        cityId: 300,
        name: "Kalawana",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Kaltota#308": {
        cityId: 308,
        name: "Kaltota",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Kiriella#372": {
        cityId: 372,
        name: "Kiriella",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Kolonna#384": {
        cityId: 384,
        name: "Kolonna",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Kuruwita#413": {
        cityId: 413,
        name: "Kuruwita",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Nivitigala#581": {
        cityId: 581,
        name: "Nivitigala",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Pallebedda#605": {
        cityId: 605,
        name: "Pallebedda",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Pambahinna#610": {
        cityId: 610,
        name: "Pambahinna",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Parakaduwa#623": {
        cityId: 623,
        name: "Parakaduwa",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Pelmadulla#633": {
        cityId: 633,
        name: "Pelmadulla",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Rakwana#689": {
        cityId: 689,
        name: "Rakwana",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Ratnapura#699": {
        cityId: 699,
        name: "Ratnapura",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Suriyakanda#731": {
        cityId: 731,
        name: "Suriyakanda",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Thimbolketiya#763": {
        cityId: 763,
        name: "Thimbolketiya",
        districtId: "9",
        districtName: "Ratnapura",
        provinceName: "Sabaragamuwa",
        provinceId: "5",
    },
    "Ambalanthota#24": {
        cityId: 24,
        name: "Ambalanthota",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Angunukolapelessa#31": {
        cityId: 31,
        name: "Angunukolapelessa",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Beliatta#80": {
        cityId: 80,
        name: "Beliatta",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Bolana#94": {
        cityId: 94,
        name: "Bolana",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Debarawewa#146": {
        cityId: 146,
        name: "Debarawewa",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Hambantota#235": {
        cityId: 235,
        name: "Hambantota",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Hungama#264": {
        cityId: 264,
        name: "Hungama",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kalametiya#299": {
        cityId: 299,
        name: "Kalametiya",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kirama#368": {
        cityId: 368,
        name: "Kirama",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kirinda#374": {
        cityId: 374,
        name: "Kirinda",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Lunugamvehera#417": {
        cityId: 417,
        name: "Lunugamvehera",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Mahawela - Nakulugama#434": {
        cityId: 434,
        name: "Mahawela - Nakulugama",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Middeniya#494": {
        cityId: 494,
        name: "Middeniya",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Nilwella#576": {
        cityId: 576,
        name: "Nilwella",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Ranna#693": {
        cityId: 693,
        name: "Ranna",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Sooriyawewa#728": {
        cityId: 728,
        name: "Sooriyawewa",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Suduwelipelessa Kirinda#729": {
        cityId: 729,
        name: "Suduwelipelessa Kirinda",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Suriyawewa#732": {
        cityId: 732,
        name: "Suriyawewa",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Tangalle#741": {
        cityId: 741,
        name: "Tangalle",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Thissamaharamaya#766": {
        cityId: 766,
        name: "Thissamaharamaya",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Tissamaharama#775": {
        cityId: 775,
        name: "Tissamaharama",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Walasmulla#824": {
        cityId: 824,
        name: "Walasmulla",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Walasmulla Rd - Weeraketiya#825": {
        cityId: 825,
        name: "Walasmulla Rd - Weeraketiya",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Weeraketiya#842": {
        cityId: 842,
        name: "Weeraketiya",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Weerawila#844": {
        cityId: 844,
        name: "Weerawila",
        districtId: "23",
        districtName: "Hambantota",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Akuressa#11": {
        cityId: 11,
        name: "Akuressa",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Bengamuwa#85": {
        cityId: 85,
        name: "Bengamuwa",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Deiyandara#151": {
        cityId: 151,
        name: "Deiyandara",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Deniyaya#157": {
        cityId: 157,
        name: "Deniyaya",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Devinuwara#161": {
        cityId: 161,
        name: "Devinuwara",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Dickwella#163": {
        cityId: 163,
        name: "Dickwella",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Dodanduwa#173": {
        cityId: 173,
        name: "Dodanduwa",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Gandara#210": {
        cityId: 210,
        name: "Gandara",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Hakmana#232": {
        cityId: 232,
        name: "Hakmana",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kakunadura#295": {
        cityId: 295,
        name: "Kakunadura",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kamburugamuwa#315": {
        cityId: 315,
        name: "Kamburugamuwa",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kamburupitiya#316": {
        cityId: 316,
        name: "Kamburupitiya",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kottegoda#400": {
        cityId: 400,
        name: "Kottegoda",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kudawella#403": {
        cityId: 403,
        name: "Kudawella",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Matara#468": {
        cityId: 468,
        name: "Matara",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Mawarala#478": {
        cityId: 478,
        name: "Mawarala",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Meddawatta#485": {
        cityId: 485,
        name: "Meddawatta",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Mirissa#502": {
        cityId: 502,
        name: "Mirissa",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Morawaka#511": {
        cityId: 511,
        name: "Morawaka",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Pallimulla#608": {
        cityId: 608,
        name: "Pallimulla",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Pitabeddara#644": {
        cityId: 644,
        name: "Pitabeddara",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Puranawella#677": {
        cityId: 677,
        name: "Puranawella",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Ransegoda  Hakmana#695": {
        cityId: 695,
        name: "Ransegoda  Hakmana",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Thihagoda#761": {
        cityId: 761,
        name: "Thihagoda",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Urubokka#799": {
        cityId: 799,
        name: "Urubokka",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Walasgala#823": {
        cityId: 823,
        name: "Walasgala",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Waralla#831": {
        cityId: 831,
        name: "Waralla",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Weligama#847": {
        cityId: 847,
        name: "Weligama",
        districtId: "5",
        districtName: "Matara",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Ahangama#3": {
        cityId: 3,
        name: "Ahangama",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Ahungalla#4": {
        cityId: 4,
        name: "Ahungalla",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Aluthwala#20": {
        cityId: 20,
        name: "Aluthwala",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Ambalangoda#23": {
        cityId: 23,
        name: "Ambalangoda",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Baddegama#52": {
        cityId: 52,
        name: "Baddegama",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Balapitiya#60": {
        cityId: 60,
        name: "Balapitiya",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Batapola#70": {
        cityId: 70,
        name: "Batapola",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Boossa#95": {
        cityId: 95,
        name: "Boossa",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Denipitiya#156": {
        cityId: 156,
        name: "Denipitiya",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Devata Junction#160": {
        cityId: 160,
        name: "Devata Junction",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Elpitiya#185": {
        cityId: 185,
        name: "Elpitiya",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Galle#201": {
        cityId: 201,
        name: "Galle",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Gonapinuwala#227": {
        cityId: 227,
        name: "Gonapinuwala",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Habaraduwa#230": {
        cityId: 230,
        name: "Habaraduwa",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Hikkaduwa#248": {
        cityId: 248,
        name: "Hikkaduwa",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Hipankanda - Uragasmanhandiya#253": {
        cityId: 253,
        name: "Hipankanda - Uragasmanhandiya",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Imaduwa#271": {
        cityId: 271,
        name: "Imaduwa",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kalaigana#298": {
        cityId: 298,
        name: "Kalaigana",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kaluwella#314": {
        cityId: 314,
        name: "Kaluwella",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Karandeniya#334": {
        cityId: 334,
        name: "Karandeniya",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Katuwana#348": {
        cityId: 348,
        name: "Katuwana",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Kotapola#395": {
        cityId: 395,
        name: "Kotapola",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Magalle#427": {
        cityId: 427,
        name: "Magalle",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Meetiyagoda#491": {
        cityId: 491,
        name: "Meetiyagoda",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Nakiyadeniya#532": {
        cityId: 532,
        name: "Nakiyadeniya",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Neluwa#570": {
        cityId: 570,
        name: "Neluwa",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Pinnaduwa - Akmeemana#643": {
        cityId: 643,
        name: "Pinnaduwa - Akmeemana",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Pitigala#645": {
        cityId: 645,
        name: "Pitigala",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Poddala#648": {
        cityId: 648,
        name: "Poddala",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Ratgama#697": {
        cityId: 697,
        name: "Ratgama",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Suduwella#730": {
        cityId: 730,
        name: "Suduwella",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Talgaswela#739": {
        cityId: 739,
        name: "Talgaswela",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Tawalama#742": {
        cityId: 742,
        name: "Tawalama",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Thalagaha - Akmeemana#747": {
        cityId: 747,
        name: "Thalagaha - Akmeemana",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Thalgampala#754": {
        cityId: 754,
        name: "Thalgampala",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Udugama#787": {
        cityId: 787,
        name: "Udugama",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Uragasmanhandiya#795": {
        cityId: 795,
        name: "Uragasmanhandiya",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Urala#796": {
        cityId: 796,
        name: "Urala",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Wanduramba#829": {
        cityId: 829,
        name: "Wanduramba",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Yakkalamulla#875": {
        cityId: 875,
        name: "Yakkalamulla",
        districtId: "4",
        districtName: "Galle",
        provinceName: "Southern",
        provinceId: "2",
    },
    "Ambagasdowa#22": {
        cityId: 22,
        name: "Ambagasdowa",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Atampitiya#44": {
        cityId: 44,
        name: "Atampitiya",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Badulla#54": {
        cityId: 54,
        name: "Badulla",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Badulla Rd - Halpe#55": {
        cityId: 55,
        name: "Badulla Rd - Halpe",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Ballekatuwa#61": {
        cityId: 61,
        name: "Ballekatuwa",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Bandarawela#65": {
        cityId: 65,
        name: "Bandarawela",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Dambagalla#142": {
        cityId: 142,
        name: "Dambagalla",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Diyathalawa#170": {
        cityId: 170,
        name: "Diyathalawa",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Girandurukotte#216": {
        cityId: 216,
        name: "Girandurukotte",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Haldummulla#233": {
        cityId: 233,
        name: "Haldummulla",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Hali Ela#234": {
        cityId: 234,
        name: "Hali Ela",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Haputale#238": {
        cityId: 238,
        name: "Haputale",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Kahaniyagoda#289": {
        cityId: 289,
        name: "Kahaniyagoda",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Kandaketiya - Loggaloya Junction#319": {
        cityId: 319,
        name: "Kandaketiya - Loggaloya Junction",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Keppetipola#361": {
        cityId: 361,
        name: "Keppetipola",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Lunugala#416": {
        cityId: 416,
        name: "Lunugala",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Madulsima#425": {
        cityId: 425,
        name: "Madulsima",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Mahiyanganaya#438": {
        cityId: 438,
        name: "Mahiyanganaya",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Mahiyankanaya#439": {
        cityId: 439,
        name: "Mahiyankanaya",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Medagama#481": {
        cityId: 481,
        name: "Medagama",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Meegahakiwla#487": {
        cityId: 487,
        name: "Meegahakiwla",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Namunukula#538": {
        cityId: 538,
        name: "Namunukula",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Passara#626": {
        cityId: 626,
        name: "Passara",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Thalangamuwa#751": {
        cityId: 751,
        name: "Thalangamuwa",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Welimada#851": {
        cityId: 851,
        name: "Welimada",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Andaulpotha#884": {
        cityId: 884,
        name: "Andaulpotha",
        districtId: "24",
        districtName: "Badulla",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Arambekema - Hambegamuwa#38": {
        cityId: 38,
        name: "Arambekema - Hambegamuwa",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Badalkumbura#51": {
        cityId: 51,
        name: "Badalkumbura",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Bibile#88": {
        cityId: 88,
        name: "Bibile",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Buttala#104": {
        cityId: 104,
        name: "Buttala",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Danduma#144": {
        cityId: 144,
        name: "Danduma",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Dobagahawela#172": {
        cityId: 172,
        name: "Dobagahawela",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Kataragama#338": {
        cityId: 338,
        name: "Kataragama",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Koslanda#391": {
        cityId: 391,
        name: "Koslanda",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Kumbukkana#407": {
        cityId: 407,
        name: "Kumbukkana",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Monaragala#506": {
        cityId: 506,
        name: "Monaragala",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Siyambalanduwa#726": {
        cityId: 726,
        name: "Siyambalanduwa",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Tanamalwila#740": {
        cityId: 740,
        name: "Tanamalwila",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Thelulla#758": {
        cityId: 758,
        name: "Thelulla",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Weliyaya#859": {
        cityId: 859,
        name: "Weliyaya",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Wellawaya#864": {
        cityId: 864,
        name: "Wellawaya",
        districtId: "25",
        districtName: "Moneragala",
        provinceName: "Uva",
        provinceId: "9",
    },
    "Ambathale#26": {
        cityId: 26,
        name: "Ambathale",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Athurugiriya#46": {
        cityId: 46,
        name: "Athurugiriya",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Avissawella#49": {
        cityId: 49,
        name: "Avissawella",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Battaramulla#72": {
        cityId: 72,
        name: "Battaramulla",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Bokundara#93": {
        cityId: 93,
        name: "Bokundara",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Boralesgamuwa#98": {
        cityId: 98,
        name: "Boralesgamuwa",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo#115": {
        cityId: 115,
        name: "Colombo",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 02#116": {
        cityId: 116,
        name: "Colombo - 02",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 03#117": {
        cityId: 117,
        name: "Colombo - 03",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 04#118": {
        cityId: 118,
        name: "Colombo - 04",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 05#119": {
        cityId: 119,
        name: "Colombo - 05",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 06#120": {
        cityId: 120,
        name: "Colombo - 06",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 07#121": {
        cityId: 121,
        name: "Colombo - 07",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 08#122": {
        cityId: 122,
        name: "Colombo - 08",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 09#123": {
        cityId: 123,
        name: "Colombo - 09",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 10#124": {
        cityId: 124,
        name: "Colombo - 10",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 11#125": {
        cityId: 125,
        name: "Colombo - 11",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 13#126": {
        cityId: 126,
        name: "Colombo - 13",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 14#127": {
        cityId: 127,
        name: "Colombo - 14",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Colombo - 15#128": {
        cityId: 128,
        name: "Colombo - 15",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Dehiwala#149": {
        cityId: 149,
        name: "Dehiwala",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ethul Kotte#191": {
        cityId: 191,
        name: "Ethul Kotte",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Gamgodawila#207": {
        cityId: 207,
        name: "Gamgodawila",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Godagama#220": {
        cityId: 220,
        name: "Godagama",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Gothatuwa#228": {
        cityId: 228,
        name: "Gothatuwa",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Hanwella#237": {
        cityId: 237,
        name: "Hanwella",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Hokandara North#256": {
        cityId: 256,
        name: "Hokandara North",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Hokandara South#257": {
        cityId: 257,
        name: "Hokandara South",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Homagama#258": {
        cityId: 258,
        name: "Homagama",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ihala Kosgama#268": {
        cityId: 268,
        name: "Ihala Kosgama",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kaduwela#288": {
        cityId: 288,
        name: "Kaduwela",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kalubowila#309": {
        cityId: 309,
        name: "Kalubowila",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kesbewa#363": {
        cityId: 363,
        name: "Kesbewa",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kohuwela#380": {
        cityId: 380,
        name: "Kohuwela",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kirulapone#388": {
        cityId: 388,
        name: "Kirulapone",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kosgama#389": {
        cityId: 389,
        name: "Kosgama",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Koswatta#392": {
        cityId: 392,
        name: "Koswatta",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kotikawatte#397": {
        cityId: 397,
        name: "Kotikawatte",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kottawa#398": {
        cityId: 398,
        name: "Kottawa",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kotte#399": {
        cityId: 399,
        name: "Kotte",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Madapatha#422": {
        cityId: 422,
        name: "Madapatha",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Madiwela#424": {
        cityId: 424,
        name: "Madiwela",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Maharagama#433": {
        cityId: 433,
        name: "Maharagama",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Malabe#446": {
        cityId: 446,
        name: "Malabe",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mattegoda#474": {
        cityId: 474,
        name: "Mattegoda",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Meegoda#489": {
        cityId: 489,
        name: "Meegoda",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mirihana#501": {
        cityId: 501,
        name: "Mirihana",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Moratuwa#508": {
        cityId: 508,
        name: "Moratuwa",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mount Lavinia#514": {
        cityId: 514,
        name: "Mount Lavinia",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mulleriyawa - New Town#519": {
        cityId: 519,
        name: "Mulleriyawa - New Town",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mulleriyawa North#520": {
        cityId: 520,
        name: "Mulleriyawa North",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Narahenpita#542": {
        cityId: 542,
        name: "Narahenpita",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Nawala#551": {
        cityId: 551,
        name: "Nawala",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Nawinna - High Level Road#553": {
        cityId: 553,
        name: "Nawinna - High Level Road",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Nugegoda#588": {
        cityId: 588,
        name: "Nugegoda",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Orugodawatta#595": {
        cityId: 595,
        name: "Orugodawatta",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Padukka#600": {
        cityId: 600,
        name: "Padukka",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Padukka - Meepe Junction#601": {
        cityId: 601,
        name: "Padukka - Meepe Junction",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pannipitiya#619": {
        cityId: 619,
        name: "Pannipitiya",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pelawetta#630": {
        cityId: 630,
        name: "Pelawetta",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Piliyandala#642": {
        cityId: 642,
        name: "Piliyandala",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Polgasowita#654": {
        cityId: 654,
        name: "Polgasowita",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Rajagiriya#686": {
        cityId: 686,
        name: "Rajagiriya",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ranala#692": {
        cityId: 692,
        name: "Ranala",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ratmalana#698": {
        cityId: 698,
        name: "Ratmalana",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Thalahena#748": {
        cityId: 748,
        name: "Thalahena",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Thalawathugoda#753": {
        cityId: 753,
        name: "Thalawathugoda",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Walgama Junction - Athrugiriya#826": {
        cityId: 826,
        name: "Walgama Junction - Athrugiriya",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Wellampitiya#862": {
        cityId: 862,
        name: "Wellampitiya",
        districtId: "1",
        districtName: "Colombo",
        provinceName: "Western",
        provinceId: "1",
    },
    "Akarawita#5": {
        cityId: 5,
        name: "Akarawita",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Attangalla#48": {
        cityId: 48,
        name: "Attangalla",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Badalgama#50": {
        cityId: 50,
        name: "Badalgama",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Baduragoda#56": {
        cityId: 56,
        name: "Baduragoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Bataleeya#69": {
        cityId: 69,
        name: "Bataleeya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Batepola - Dunagaha#71": {
        cityId: 71,
        name: "Batepola - Dunagaha",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Batuwatta#78": {
        cityId: 78,
        name: "Batuwatta",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Bemmulla#84": {
        cityId: 84,
        name: "Bemmulla",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Biyagama#90": {
        cityId: 90,
        name: "Biyagama",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Dalupotha#138": {
        cityId: 138,
        name: "Dalupotha",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Delgoda#152": {
        cityId: 152,
        name: "Delgoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Demanhandiya - Miriswatta#155": {
        cityId: 155,
        name: "Demanhandiya - Miriswatta",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Dewalapola#162": {
        cityId: 162,
        name: "Dewalapola",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Dikowita#164": {
        cityId: 164,
        name: "Dikowita",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Divulapitiya#166": {
        cityId: 166,
        name: "Divulapitiya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Dompe#176": {
        cityId: 176,
        name: "Dompe",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Dunagaha#179": {
        cityId: 179,
        name: "Dunagaha",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ekala#183": {
        cityId: 183,
        name: "Ekala",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Gampaha#208": {
        cityId: 208,
        name: "Gampaha",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ganemulla#212": {
        cityId: 212,
        name: "Ganemulla",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Giridara#217": {
        cityId: 217,
        name: "Giridara",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Hedala#242": {
        cityId: 242,
        name: "Hedala",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Heiyanthuduwa#243": {
        cityId: 243,
        name: "Heiyanthuduwa",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Idigolla#267": {
        cityId: 267,
        name: "Idigolla",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Jaela#279": {
        cityId: 279,
        name: "Jaela",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kadawatha#285": {
        cityId: 285,
        name: "Kadawatha",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kal - Eliya#296": {
        cityId: 296,
        name: "Kal - Eliya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kalagedihena#297": {
        cityId: 297,
        name: "Kalagedihena",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kandana#322": {
        cityId: 322,
        name: "Kandana",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Katana#336": {
        cityId: 336,
        name: "Katana",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kattuwa#341": {
        cityId: 341,
        name: "Kattuwa",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Katunayake#345": {
        cityId: 345,
        name: "Katunayake",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Katuwellagama Junction#349": {
        cityId: 349,
        name: "Katuwellagama Junction",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kelaniya#357": {
        cityId: 357,
        name: "Kelaniya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kimbulapitiya#366": {
        cityId: 366,
        name: "Kimbulapitiya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kiribathgoda#371": {
        cityId: 371,
        name: "Kiribathgoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kirillawela - Imbulgoda#373": {
        cityId: 373,
        name: "Kirillawela - Imbulgoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kirindiwela#375": {
        cityId: 375,
        name: "Kirindiwela",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kochchikade#378": {
        cityId: 378,
        name: "Kochchikade",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kotadeniyawa#393": {
        cityId: 393,
        name: "Kotadeniyawa",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kotugoda#401": {
        cityId: 401,
        name: "Kotugoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kurana - Katunayaka#410": {
        cityId: 410,
        name: "Kurana - Katunayaka",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Loluwagoda#415": {
        cityId: 415,
        name: "Loluwagoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mabima#419": {
        cityId: 419,
        name: "Mabima",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Madampella#421": {
        cityId: 421,
        name: "Madampella",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mahabage#431": {
        cityId: 431,
        name: "Mahabage",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mahara#432": {
        cityId: 432,
        name: "Mahara",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Makola#444": {
        cityId: 444,
        name: "Makola",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Malwatu - Hiripitiya#450": {
        cityId: 450,
        name: "Malwatu - Hiripitiya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Marandagahamula#461": {
        cityId: 461,
        name: "Marandagahamula",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mawaramandiya#479": {
        cityId: 479,
        name: "Mawaramandiya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Millathe - Kiridiwela#496": {
        cityId: 496,
        name: "Millathe - Kiridiwela",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Minuwangoda#499": {
        cityId: 499,
        name: "Minuwangoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mirigama#500": {
        cityId: 500,
        name: "Mirigama",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Mudungoda#516": {
        cityId: 516,
        name: "Mudungoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Nagoda#529": {
        cityId: 529,
        name: "Nagoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Nalla#534": {
        cityId: 534,
        name: "Nalla",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Naranwala#545": {
        cityId: 545,
        name: "Naranwala",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Negombo#559": {
        cityId: 559,
        name: "Negombo",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Nelligahamulla - Atthanagalla#567": {
        cityId: 567,
        name: "Nelligahamulla - Atthanagalla",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Nittambuwa#579": {
        cityId: 579,
        name: "Nittambuwa",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pallewela#607": {
        cityId: 607,
        name: "Pallewela",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pamunugama#611": {
        cityId: 611,
        name: "Pamunugama",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pasyala#627": {
        cityId: 627,
        name: "Pasyala",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Peliyagoda#631": {
        cityId: 631,
        name: "Peliyagoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pitipana#646": {
        cityId: 646,
        name: "Pitipana",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pitipana - South#647": {
        cityId: 647,
        name: "Pitipana - South",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pohonnaruwa - Meerigama#649": {
        cityId: 649,
        name: "Pohonnaruwa - Meerigama",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pugoda#667": {
        cityId: 667,
        name: "Pugoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Puwakwetiya - Kadawata#682": {
        cityId: 682,
        name: "Puwakwetiya - Kadawata",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Raddolugama#683": {
        cityId: 683,
        name: "Raddolugama",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ragama#685": {
        cityId: 685,
        name: "Ragama",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ranpokunugama#694": {
        cityId: 694,
        name: "Ranpokunugama",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Seeduwa#713": {
        cityId: 713,
        name: "Seeduwa",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Siyambalape#727": {
        cityId: 727,
        name: "Siyambalape",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Thibirigaskatuwa#760": {
        cityId: 760,
        name: "Thibirigaskatuwa",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Thihariya#762": {
        cityId: 762,
        name: "Thihariya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Udugampola#788": {
        cityId: 788,
        name: "Udugampola",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Urapola#798": {
        cityId: 798,
        name: "Urapola",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Veyangoda#814": {
        cityId: 814,
        name: "Veyangoda",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Wattala#834": {
        cityId: 834,
        name: "Wattala",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Webada#840": {
        cityId: 840,
        name: "Webada",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Welisara - Kandana#856": {
        cityId: 856,
        name: "Welisara - Kandana",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Welisara - Maththumagala#857": {
        cityId: 857,
        name: "Welisara - Maththumagala",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Weliweriya#858": {
        cityId: 858,
        name: "Weliweriya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Weweldeniya#869": {
        cityId: 869,
        name: "Weweldeniya",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Wilimbula - Henegama#871": {
        cityId: 871,
        name: "Wilimbula - Henegama",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Yakkala#874": {
        cityId: 874,
        name: "Yakkala",
        districtId: "2",
        districtName: "Gampaha",
        provinceName: "Western",
        provinceId: "1",
    },
    "Agalawatta#2": {
        cityId: 2,
        name: "Agalawatta",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Aluthgama#19": {
        cityId: 19,
        name: "Aluthgama",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Anguruwathota#32": {
        cityId: 32,
        name: "Anguruwathota",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Arakawila - Handapangoda#36": {
        cityId: 36,
        name: "Arakawila - Handapangoda",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Badureliya#57": {
        cityId: 57,
        name: "Badureliya",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Bandaragama#63": {
        cityId: 63,
        name: "Bandaragama",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Batagoda#68": {
        cityId: 68,
        name: "Batagoda",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Bellapitiya - Anguruwathota#83": {
        cityId: 83,
        name: "Bellapitiya - Anguruwathota",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Beruwela - Gorakadoowa#86": {
        cityId: 86,
        name: "Beruwela - Gorakadoowa",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Beruwala#87": {
        cityId: 87,
        name: "Beruwala",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Bulathsinhala#102": {
        cityId: 102,
        name: "Bulathsinhala",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Dodangoda#175": {
        cityId: 175,
        name: "Dodangoda",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Horana#261": {
        cityId: 261,
        name: "Horana",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ingiriya#274": {
        cityId: 274,
        name: "Ingiriya",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Ittapana#277": {
        cityId: 277,
        name: "Ittapana",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kalutara North#311": {
        cityId: 311,
        name: "Kalutara North",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kalutara South#312": {
        cityId: 312,
        name: "Kalutara South",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Katukurunda#343": {
        cityId: 343,
        name: "Katukurunda",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Kumbuka West#405": {
        cityId: 405,
        name: "Kumbuka West",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Maggona#428": {
        cityId: 428,
        name: "Maggona",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Matugama#475": {
        cityId: 475,
        name: "Matugama",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Molligoda#504": {
        cityId: 504,
        name: "Molligoda",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Moragahahena#507": {
        cityId: 507,
        name: "Moragahahena",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Morontuduwa#513": {
        cityId: 513,
        name: "Morontuduwa",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Neboda#556": {
        cityId: 556,
        name: "Neboda",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Panadura#612": {
        cityId: 612,
        name: "Panadura",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Panapitiya - Kaluthara#613": {
        cityId: 613,
        name: "Panapitiya - Kaluthara",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Payagala#628": {
        cityId: 628,
        name: "Payagala",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pelawatte#629": {
        cityId: 629,
        name: "Pelawatte",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Pokunuwita#651": {
        cityId: 651,
        name: "Pokunuwita",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Polegoda - Mahagama#652": {
        cityId: 652,
        name: "Polegoda - Mahagama",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Poruwadanda#660": {
        cityId: 660,
        name: "Poruwadanda",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Wadduwa#819": {
        cityId: 819,
        name: "Wadduwa",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Wekada - panadura#845": {
        cityId: 845,
        name: "Wekada - panadura",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Welipenna#853": {
        cityId: 853,
        name: "Welipenna",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Welipenne - Site B#855": {
        cityId: 855,
        name: "Welipenne - Site B",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
    "Yatadola - Mathugama#877": {
        cityId: 877,
        name: "Yatadola - Mathugama",
        districtId: "3",
        districtName: "Kaluthara",
        provinceName: "Western",
        provinceId: "1",
    },
}